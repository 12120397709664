<script setup lang="ts">
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VListItem from "@magnit/core/src/components/VListItem/VListItem.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import type { ICategory } from "~/typings/categories";

const props = defineProps<{
  current?: ICategory;
  items: ICategory[];
  open?: boolean;
  root?: boolean;
}>();
const emit = defineEmits<{
  "menu:back": [];
  "item:click": [category: ICategory];
}>();

const categoriesStore = useCategoriesStore();
const { send } = useAnalytics();

const next = ref<ICategory | null>(null);

const onBackClick = () => {
  emit("menu:back");
};

const onPrevBack = () => {
  next.value = null;
};

const onItemClick = (category: ICategory) => {
  if (category.children.length) {
    next.value = category;
  } else {
    const categoryName = categoriesStore.getTreeNameByKey(category.key);
    send("CatalogMenu:Item:Click", { categoryName });
    emit("item:click", category);
  }
};

const onPrevItemClick = (category: ICategory) => {
  emit("item:click", category);
};

const onMainClick = () => {
  if (props.current) {
    const categoryName = categoriesStore.getTreeNameByKey(props.current.key);
    send("CatalogMenu:Item:Click", { categoryName });
    emit("item:click", props.current);
  }
};

watch(
  () => props.current,
  () => {
    next.value = null;
  },
);

watch(
  () => props.open,
  (nextOpen) => {
    if (!nextOpen) {
      next.value = null;
    }
  },
);
</script>

<template>
  <div ref="rootElement" class="header-catalog-step">
    <div v-if="next" class="header-catalog-step__next-wrapper">
      <div class="header-catalog-step__next">
        <AppCatalogNavigation
          :items="next.children"
          :current="next"
          @menu:back="onPrevBack"
          @item:click="onPrevItemClick"
        />
      </div>
    </div>

    <div class="header-catalog-step__content">
      <div v-if="current" class="header-catalog-caption">
        <VListItem
          class="header-catalog-item header-catalog-item_back"
          :divider="false"
          valign="center"
          @click="onBackClick"
        >
          <VInlineButton icon-position="left" theme="secondary">
            <template #icon>
              <VIcon rotate="180deg" name="24-outline-right-corner" size="24" />
            </template>

            Назад
          </VInlineButton>
        </VListItem>

        <VListItem
          class="header-catalog-item header-catalog-item_main"
          :divider="false"
          valign="center"
        >
          <VText font="title-medium">
            {{ current.name }}
          </VText>

          <template v-if="current.icon" #left>
            <AppImage
              :key="current.icon"
              class="header-catalog-item__img"
              :src="current.icon"
              :alt="current.name"
            />
          </template>
        </VListItem>

        <VListItem
          class="header-catalog-item"
          valign="center"
          :divider="false"
          @click="onMainClick"
        >
          Все товары этой категории
        </VListItem>
      </div>

      <div class="header-catalog-items">
        <VListItem
          v-for="item in items"
          :key="item.key"
          class="header-catalog-item"
          :class="{ 'header-catalog-item_active': next?.key === item.key }"
          :divider="false"
          valign="center"
          @click="onItemClick(item)"
        >
          <VText
            :font="
              root || item.icon ? 'body-large-accent' : 'body-large-regular'
            "
          >
            {{ item.name }}
          </VText>

          <template v-if="item.icon" #left>
            <AppImage
              :key="item.icon"
              class="header-catalog-item__img"
              :src="item.icon"
              :alt="item.name"
            />
          </template>

          <template v-if="item.children.length" #right>
            <VIcon
              color="secondary"
              name="24-outline-right-small-corner"
              class="header-catalog-item__icon"
            />
          </template>
        </VListItem>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.header-catalog-step {
  position: relative;
  height: 100%;

  &__next-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    background-color: var(--pl-surface-primary-default);
    z-index: var(--pl-z-index-level1);
  }

  &__next {
    height: 100%;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--pl-divider-primary);
      top: -1px;
      left: 0;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & > &__next-wrapper {
    @media (--pl-viewport-from-xl) {
      left: calc(100% - 1px);
      border-left: 1px solid var(--pl-divider-primary);
    }
  }
}

.header-catalog-caption {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: var(--pl-unit-x5);
    right: var(--pl-unit-x5);
    bottom: 0;
    background: var(--pl-divider-primary);
    height: 1px;
  }
}

.header-catalog-items {
  overflow-y: auto;
  height: 100%;
}

.header-catalog-item {
  .header-catalog-step & {
    cursor: pointer;
    margin: 0 calc(var(--pl-wrapper-indent) - var(--pl-unit-x2));
    padding: var(--pl-unit-x3) var(--pl-unit-x2);
    border-radius: var(--pl-unit-x3);

    @media (--pl-viewport-from-ml) {
      margin: 0 var(--pl-unit-x2);
    }
  }

  &:hover {
    background-color: var(--pl-surface-primary-hover);
  }

  &:active {
    background-color: var(--pl-surface-primary-pressed);
  }

  &_active {
    background-color: var(--pl-surface-primary-pressed);
  }

  & :deep(img) {
    width: var(--pl-unit-x8);
    height: var(--pl-unit-x8);
    border-radius: var(--pl-unit-x2);
    overflow: hidden;
  }

  &_back {
    .header-catalog-caption & {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: var(--pl-unit-x4);

      @media (--pl-viewport-from-xl) {
        display: none;
      }
    }
  }

  &_main {
    .header-catalog-caption & {
      height: 64px;
      cursor: default;
      background: transparent;
    }
  }

  &__icon {
    margin: auto;
  }
}
</style>
