<script setup lang="ts">
import { vIntersectionObserver } from "@vueuse/components";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import ButterflySVG from "~/assets/svg/butterflies/home-auth-banner.svg";

const { isAuthorized } = useAuth();
const { authorizeUser } = useAuthDestination();

const title = "Запомним любимый магазин, покажем баланс бонусов и магнитиков";

// Analytics
const { send } = useAnalytics();
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:Promo:Reg:View");
  }
};

const onButtonClick = () => {
  authorizeUser();
  send("MainPage:Promo:Reg:Click", {
    title,
  });
};
</script>

<template>
  <VWrapperLocal v-if="!isAuthorized" v-intersection-observer="onVisibility">
    <div class="auth-banner-butterfly-wrap">
      <ButterflySVG />
    </div>

    <div class="auth-banner" @click="onButtonClick">
      <div class="auth-banner__box">
        <img
          class="auth-banner__image"
          src="~/assets/images/auth-banner.png"
          alt="Хочу попробовать"
        >
      </div>

      <VButton
        theme="invert"
        class="auth-banner__button auth-banner__button_web"
      >
        Хочу попробовать
      </VButton>

      <div class="auth-banner__wrapper">
        <VText
          :font="{
            xs: 'title-large',
            s: 'headline-small',
            m: 'title-large',
            ml: 'headline-small',
            xl: 'headline-small',
          }"
          color="invert-primary"
          tag="div"
          class="auth-banner__title"
        >
          {{ title }}
        </VText>

        <VText
          font="body-large-accent"
          color="invert-primary"
          tag="div"
          class="auth-banner__text"
        >
          В вашем личном кабинете
        </VText>

        <VButton theme="invert" class="auth-banner__button">
          Хочу попробовать
        </VButton>
      </div>
    </div>
  </VWrapperLocal>
</template>

<style lang="postcss" scoped>
.auth-banner {
  margin-top: 48px;
  margin-bottom: 54px;
  padding: 12px;
  background: #680c34;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease-out;
  position: relative;

  &-butterfly-wrap {
    position: relative;
    display: none;
  }

  &-butterfly {
    position: absolute;
    left: 0;
    top: -80px;
    z-index: var(--pl-z-index-negative);
  }

  &:hover {
    background: #530a2b;
  }

  &__box {
    position: relative;
  }

  &__image {
    display: block;
    height: 132px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 6px;
  }

  &__text {
    margin-bottom: 14px;
  }

  &__button {
    width: 100%;

    &_web {
      display: none;
      order: 3;
      flex-shrink: 0;
      margin-left: 20px;
    }
  }
}

@media (--pl-viewport-from-s) {
  .auth-banner {
    &__image {
      height: 142px;
    }
  }
}

@media (--pl-viewport-from-m) {
  .auth-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 20px;
    margin-bottom: 58px;

    &__wrapper {
      margin-right: 14px;
    }

    &__box {
      order: 2;
      margin: 0;
      width: 80%;
    }

    &__image {
      height: 178px;
      position: absolute;
      right: -104px;
      bottom: -124px;
    }

    &__button {
      width: initial;
    }
  }
}

@media (--pl-viewport-from-ml) {
  .auth-banner {
    margin-top: 64px;
    margin-bottom: 70px;

    &__box {
      width: 85%;
    }

    &__image {
      height: 220px;
      right: -64px;
    }
  }
}

@media (--pl-viewport-from-l) {
  .auth-banner {
    padding: 24px 40px;
    margin-top: 96px;
    margin-bottom: 82px;
    border-radius: 24px;

    &-butterfly-wrap {
      display: block;
    }

    &__box {
      width: 70%;
    }

    &__image {
      height: 180px;
      right: -52px;
      bottom: -100px;
    }

    &__text {
      margin-bottom: 0;
    }

    &__button {
      display: none;

      &_web {
        display: block;
      }
    }
  }
}

@media (--pl-viewport-from-xl) {
  .auth-banner {
    margin-top: 104px;
    padding: 40px;

    &__box {
      width: 30%;
    }

    &__image {
      height: 186px;
      right: -36px;
    }

    &__button_web {
      margin-left: 0;
    }
  }
}
</style>
