export const APP_COOKIE_MAX_AGE_YEAR = 31556952; // 1 Year
export const APP_MAIN_PAGE_CATEGORY_BANNER_INDEX = 2;
export const APP_VERSION = "7.0.0";
export const APP_INSTALL_URL = "https://redirect.appmetrica.yandex.com/serve/1109920877077669781";
export const APP_LOCAL_STORAGE_RUNTIME_CONFIG_EXT = "m-conf";
export const APP_COOKIE_STORAGE_KEYS = {
  /**
   * Уникальный рандомный ключ, по которому backend идентифицирует пользователя и агрегирует запросы
   */
  uniqDeviceID: "mg_udi",
  /**
   * Ключи авторизации
   */
  jwt: "mg_at",
  /**
   * Ключ аутентификации пользователя (нужен для OTP и регистрации), хранится в SessionStorage
   */
  magnitIDCode: "mg_uic",
  /**
   * Параметры входа логина для обработки перезагрузок
   */
  savedOTPData: "mg_sod",
  /**
   * Храним идентификатор сессии для kfp
   */
  ksid: "mg_ksid",
  /**
   * Храним статус, что пользователь недозарегистрирован
   */
  incompleteProfile: "mg_icp",
  /**
   * Храним подтверждение 18+
   */
  isAdult: "mg_adlt",
  /**
   * Идентификатор для выбранного магазина, только для промо каталога. Удалить после перехода на нормальное АПИ.
   */
  shopId: "nmg_sid",
  shopCity: "nmg_cty",
  shopCode: "shopCode",
  /**
   * Храним флаг что магазин выбран/подтвержден
   */
  storePrompted: "nmg_sp",
};

export const APP_LOCAL_STORAGE_KEYS = {
  /**
   * Храним UUID пользователя
   */
  uuid: "mg_uid",
};
