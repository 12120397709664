<script lang="ts" setup>
import { vElementVisibility } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import ArrowIcon from "@magnit/icons/src/assets/svg/icons/base/24-outline-right-corner.svg";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VProductPreview from "@magnit/unit-catalog/src/components/VProductPreview.vue";
import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";
import type { IResponsive, IResponsiveOnly } from "@magnit/core/types";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import { Swiper as SwiperClass } from "swiper";
import type { ISwiperOptions } from "~/typings/components/appSlider";
import { IYaSlotApiStatus, useSlotsStore } from "~/store/useSlotsStore";

const props = withDefaults(
  defineProps<{
    title?: string;
    link?: string;
    products: IProduct[];
    adfoxPayload?: IYaSlotConfig | null;
    bannerPosition?: number;
    withBackground?: boolean;
    titleWrapperIndent?: IResponsive<string>;
    sliderWrapperIndent?: IResponsive<string>;
    swiperBreakpoints?: IResponsiveOnly<ISwiperOptions>;
    statPrefix?: string;
  }>(),
  {
    adfoxPayload: null,
    bannerPosition: 4,
    title: "",
    link: "",
    statPrefix: "",
    titleWrapperIndent: () => ({ xs: "12px", s: "20px", m: "32px" }),
    sliderWrapperIndent: () => ({ l: "32px" }),
    swiperBreakpoints: () => ({
      xs: {
        slidesPerView: "auto",
        navigation: false,
        spaceBetween: 8,
        slidesOffsetAfter: 12,
        slidesOffsetBefore: 12,
      },
      s: {
        spaceBetween: 12,
        slidesOffsetAfter: 20,
        slidesOffsetBefore: 20,
      },
      m: {
        spaceBetween: 20,
        slidesOffsetAfter: 32,
        slidesOffsetBefore: 32,
      },
      l: {
        slidesPerView: 4,
        spaceBetween: 24,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      xl: {
        slidesPerView: 6,
        spaceBetween: 24,
        navigation: true,
      },
    }),
  },
);

const stubedSlides = ref<number[]>([]);

const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const bannerIndex = computed(() =>
  props.adfoxPayload &&
  props.bannerPosition &&
  props.bannerPosition - 1 < props.products.length
    ? props.bannerPosition - 1
    : undefined,
);
const displayProducts = computed(() => {
  const slides = [...props.products] as (IProduct | IYaSlotConfig)[];
  if (bannerIndex.value && slotApi.slotStatus === IYaSlotApiStatus.SUCCESS) {
    slides.splice(bannerIndex.value, 0, props.adfoxPayload!);
  }
  return slides.reduce(
    (acc, item, idx) => {
      if (!stubedSlides.value.includes(idx)) {
        acc.push(item);
      }
      return acc;
    },
    [] as (IProduct | IYaSlotConfig)[],
  );
});

const swiper = ref<Nullable<SwiperClass>>();

const onVisibility = (v: boolean) => v && send(`${props.statPrefix}:View`);

const onSlideVisibility = (
  v: boolean,
  params: {
    idx: number;
    item: IProduct;
  },
) => {
  if (!props.statPrefix || !v) return;

  send(`${props.statPrefix}:Item:View`, collectProductsSliderPayload(params));
};
const onSlideClick = (params: { idx: number; item: IProduct; }) => {
  if (!props.statPrefix) return;
  send(`${props.statPrefix}:Item:Click`, collectProductsSliderPayload(params));
};
const onSwiper = (s: SwiperClass) => (swiper.value = s);
const onSlideStab = (index: number) => {
  stubedSlides.value.push(index);
};
</script>

<template>
  <div
    v-if="products.length"
    v-element-visibility="onVisibility"
    class="category-products"
    :class="{ 'with-bg': props.withBackground }"
  >
    <VWrapper :class="{ 'category-products__bg': props.withBackground }">
      <VWrapper :indent="titleWrapperIndent" class="category-products__header">
        <VText
          v-if="title"
          :font="{ xs: 'headline-small', l: 'headline-large' }"
        >
          {{ title }}
        </VText>
        <VAppLink v-if="link" :to="link">
          <VButton theme="tertiary" :square="{ xs: true, ml: false }">
            <template #icon>
              <ArrowIcon />
            </template>
            <VText font="body-large-accent">
              Посмотреть всё
            </VText>
          </VButton>
        </VAppLink>
      </VWrapper>
      <VWrapper :indent="sliderWrapperIndent">
        <AppSlider
          slides-per-view="auto"
          :breakpoints="swiperBreakpoints"
          @on-swiper="onSwiper"
        >
          <AppSliderSlide
            v-for="(item, idx) in displayProducts"
            :key="item.id"
            :size="{ xs: 6, m: 4, ml: 3, xl: 6, l: 'auto' }"
          >
            <VProductPreview
              v-if="'link' in item"
              v-element-visibility="
                (arg) =>
                  onSlideVisibility(arg, { idx, item } as {
                    idx: number;
                    item: IProduct;
                  })
              "
              v-bind="item"
              class="category-products__item"
              @click="onSlideClick({ idx, item })"
            />
            <VYaSlot
              v-else-if="adfoxPayload"
              class="category-products__item"
              :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
              :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
              :owner-id="adfoxOwnerId"
              :config="adfoxPayload"
              has-product
              :analytics-sender="send"
              :analytics-view="{
                name: `${statPrefix}:Item:View`,
                options: {
                  position: idx + 1,
                },
              }"
              :analytics-click="{
                name: `${statPrefix}:Item:Click`,
                options: {
                  position: idx + 1,
                },
              }"
              @stub="onSlideStab(idx)"
            />
          </AppSliderSlide>
        </AppSlider>
      </VWrapper>
    </VWrapper>
  </div>
</template>

<style lang="postcss" scoped>
.category-products {
  margin-top: var(--pl-unit-x8);

  @media (--pl-viewport-from-l) {
    margin-top: 48px;

    &.with-bg {
      margin-top: var(--pl-unit-x8);
    }
  }

  &__bg {
    position: relative;
    border-radius: var(--pl-unit-x5);
    padding-top: 38px;
    padding-bottom: var(--pl-unit-x8);
    background: linear-gradient(
      113deg,
      #edffcb 16.52%,
      #def7b0 29.99%,
      #def7b0 47.76%,
      #deffa1 99.82%
    );

    @media (--pl-viewport-from-l) {
      padding-top: 50px;
    }
  }

  &__bg::before {
    content: "";
    position: absolute;
    left: 40%;
    top: 0;
    transform: rotate(-125deg);
    width: 134px;
    height: 134px;
    background: 0 0 / 100% no-repeat url("/np/icons/star-56.webp");

    @media (--pl-viewport-from-m) {
      left: 110px;
      width: 188px;
      height: 188px;
    }

    @media (--pl-viewport-from-l) {
      top: -10px;
      left: 160px;
      transform: rotate(-127deg);
    }

    @media (--pl-viewport-from-xl) {
      top: 0;
    }
  }

  &__bg::after {
    @media (--pl-viewport-from-m) {
      content: "";
      position: absolute;
      right: 48px;
      top: var(--pl-unit-x1);
      transform: rotate(-166deg);
      width: 127px;
      height: 127px;
      z-index: 0;
      background: 0 0 / 100% no-repeat url("/np/icons/star-55.webp");
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--pl-unit-x2);
    position: relative;
    z-index: 1;

    @media (--pl-viewport-from-l) {
      margin-bottom: 18px;
    }
  }

  &__item {
    height: 100%;
  }

  & :deep(.slider-wrapper) {
    display: flex;
    align-items: stretch;
    align-content: stretch;
  }

  & :deep(.pl-button-inline__icon) {
    padding: 10px;

    @media (--pl-viewport-from-ml) {
      padding: 10px 0;
    }
  }
}
</style>
