import * as Sentry from "@sentry/nuxt";
import { useRuntimeConfig } from "#imports";

Sentry.init({
  // If set up, you can use your runtime config here
  dsn: useRuntimeConfig().public.sentry.dsn,
  ignoreErrors: ["_0x2dbf47", "This browser doesn't support the API's required to use the Firebase SDK"],
  beforeSend(event) {
    const frames = event?.exception?.values?.length && event.exception.values[0]?.stacktrace?.frames ? event?.exception?.values?.[0]?.stacktrace?.frames : [];

    /**
     *  Игнорируем ошибки из kfp, ибо они спамят в Sentry, а мы не можем повлиять на них
     *  Летит много ошибок вида "Cannot read properties of undefined (reading 'toLowerCase')"
     */
    const isObf = frames.some((frame) => frame.filename.includes("das.obf.js"));
    if (isObf) {
      return null;
    }
    return event;
  },
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  // integrations: [Sentry.replayIntegration()],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
