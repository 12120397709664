import type { PublicRuntimeConfig } from "nuxt/schema";

export default () => {
  const { public: publicConfig } = useRuntimeConfig();
  const keyArr: string[] = [];

  const getValue = (name: string, cfg: PublicRuntimeConfig) => {
    const cfgValue = cfg[name] ?? null;
    if (import.meta.server) return cfgValue;
    const config = localStorage.getItem(APP_LOCAL_STORAGE_RUNTIME_CONFIG_EXT);
    if (!config) return cfgValue;
    const parsed = JSON.parse(config);
    return parsed[name] ?? cfgValue;
  };
  const getComputed = (key: string) => {
    keyArr.push(key);
    return computed(() => getValue(key, publicConfig));
  };
  const logConfig = () => {
    const logArr = keyArr.map((key) => ({ name: key, value: getValue(key, publicConfig) }));
    logComponent("PUBLIC CONFIG", logArr);
  };

  return {
    logConfig,
    config: getComputed("config"),
    yandexCaptcha: getComputed("yandexCaptcha"),
    kfpCid: getComputed("kfpCid"),
    version: getComputed("version"),
    debug: getComputed("debug"),
    adfoxOwnerId: getComputed("adfoxOwnerId"),
    cookieDomain: getComputed("cookieDomain"),
    weekProductsCategory: getComputed("weekProductsCategory"),
  };
};
