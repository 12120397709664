import { useFetch, useNuxtApp } from "nuxt/app";
import type { Fetch } from "ofetch";
import type { IRequestParams } from "@magnit/layer-api/typings";

export const useTransport = async <R = void, D = R>(
  url: MaybeRef<string>,
  params?: IRequestParams<R, D>,
  transport?: Fetch,
) => {
  const $fetch = useNuxtApp().$transport;
  return useFetch(url, {
    ...params,
    $fetch: (transport || $fetch) as typeof $fetch,
  });
};
