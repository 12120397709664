<script setup lang="ts">
import { ref } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import { Swiper as SwiperClass } from "swiper";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import type { IResponsiveOnly } from "@magnit/core/types";
import { useContentStore } from "~/store/content";
import type { ISwiperOptions } from "~/typings/components/appSlider";

withDefaults(
  defineProps<{
    loading?: false;
    breakpoints?: IResponsiveOnly<ISwiperOptions>;
  }>(),
  {
    breakpoints: () => ({
      xs: {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      s: {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      m: {
        slidesPerView: "auto",
        spaceBetween: 20,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 32,
        slidesOffsetBefore: 32,
      },
      ml: {
        slidesPerView: "auto",
        spaceBetween: 20,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      l: {
        slidesPerView: "auto",
        spaceBetween: 24,
        pagination: {
          enabled: true,
        },
        navigation: true,
      },
      xl: {
        slidesPerView: "auto",
        spaceBetween: 24,
        pagination: {
          enabled: true,
        },
        navigation: true,
      },
    }),
  },
);

// Analytics
const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const contentStore = useContentStore();
const isVisible = ref(false);
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting) {
    send("MainPage:UpperSlider:View");
  }
};

const onSlideScroll = (swiper: SwiperClass) => {
  send("MainPage:UpperSlider:Scroll", {
    scroll_line: swiper.realIndex > swiper.previousIndex ? "right" : "left",
  });
};

const onStabAdfox = (index: number) => {
  contentStore.mainPageAdfoxSplice(index, 1);
};
</script>

<template>
  <div class="main-slider-wrapper">
    <VWrapper
      v-if="contentStore.mainPage?.adfox.length"
      class="main-slider"
      :indent="{ l: '32px' }"
    >
      <AppSlider
        v-intersection-observer="onVisibility"
        autoplay
        :loop="contentStore.mainPage.adfox.length >= 4"
        :initial-slides-count="contentStore.mainPage.adfox.length"
        :breakpoints="breakpoints"
        @slide-change-by-user="onSlideScroll"
        @navigation-next="onSlideScroll"
        @navigation-prev="onSlideScroll"
      >
        <AppSliderSlide
          v-for="(config, index) in contentStore.mainPage.adfox"
          :key="config.payload[0].id"
          :size="{ xs: 12, s: 12, m: 8, ml: 8, l: 8, xl: 6 }"
        >
          <VYaSlot
            :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
            :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
            :owner-id="adfoxOwnerId"
            :config="config.payload"
            :analytics-sender="send"
            :analytics-view="{
              name: 'MainPage:UpperSlider:Item:View',
              options: {
                position: index + 1,
              },
            }"
            :analytics-click="{
              name: 'MainPage:UpperSlider:Item:Click',
              options: {
                position: index + 1,
              },
            }"
            :disclaimer="config.disclaimer"
            @stub="onStabAdfox(index)"
          />
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </div>
</template>

<style lang="postcss">
.main-slider-wrapper {
  .main-slider {
    padding-bottom: 30px;
    margin-bottom: 32px;

    .swiper {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .ad-block-message {
    aspect-ratio: 1.8;
  }

  .slider-slide {
    overflow: hidden;
    border-radius: 20px;

    & .disclaimer {
      bottom: var(--pl-unit-x2);
      right: var(--pl-unit-x5);
    }
  }
}

@media (--pl-viewport-from-s) {
  .main-slider-wrapper .main-slider .swiper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (--pl-viewport-from-m) {
  .main-slider-wrapper {
    .main-slider {
      padding-bottom: 22px;

      .swiper {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    .slider-slide {
      border-radius: 24px;
    }
  }
}

@media (--pl-viewport-from-ml) {
  .main-slider-wrapper {
    .main-slider {
      margin-bottom: 40px;
    }

    .slider-slide {
      border-radius: 28px;
    }
  }
}

@media (--pl-viewport-from-l) {
  .main-slider-wrapper {
    .main-slider {
      padding-bottom: 0;

      .swiper {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .slider-slide {
      border-radius: 32px;

      & .disclaimer {
        right: var(--pl-unit-x6);
      }
    }
  }
}
</style>
