<script setup lang="ts">
import { ref } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import { Swiper as SwiperClass } from "swiper";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import ButterflySVG from "~/assets/svg/butterflies/home-useful.svg";
import VTitle from "~/components/VTitle.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import VUsefulItem from "~/containers/VUseful/VUsefulItem.vue";
import { useContentStore } from "~/store/content";
import type { IPublication } from "~/store/articles";
import { Routes } from "~/utils/routes";
import { getErid } from "~/utils/string";

const stackGap = { xs: "8px", s: "12px", m: "20px", l: "24px" };
const contentStore = useContentStore();
const items = computed(() =>
  contentStore.mainPage?.publications &&
  contentStore.mainPage?.publications?.length >= 7
    ? contentStore.mainPage?.publications.slice(0, 7)
    : [],
);

const sliderItems = computed(() =>
  items.value.length === 7 ? items.value.slice(1, -1) : [],
);
const middleItems = computed(() =>
  items.value.length === 7 ? items.value.slice(2, -2) : [],
);

// Analytics
const { send } = useAnalytics();
const root = ref(null);

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:Useful:View");
  }
};

function onVisibilityItem(
  item: IPublication,
  [{ isIntersecting }]: IntersectionObserverEntry[],
) {
  if (isIntersecting) {
    send("MainPage:Useful:Item:View", {
      item_name: item.title,
      dest: `${Routes.Articles}/${item.article.id}-${item.article.code}`,
      erid: getErid(item.disclaimer),
    });
  }
}

const onItemClick = (item: IPublication) => {
  send("MainPage:Useful:Item:Click", {
    item_name: item.title,
    dest: `${Routes.Articles}/${item.article.id}-${item.article.code}`,
    erid: getErid(item.disclaimer),
  });
};
const onSlideScroll = (swiper: SwiperClass) => {
  send("MainPage:Useful:Scroll", {
    scroll_line: swiper.realIndex > swiper.previousIndex ? "right" : "left",
  });
};
</script>

<template>
  <div v-if="items.length === 7" class="useful">
    <VWrapperLocal ref="root" v-intersection-observer="onVisibility">
      <div class="useful-butterfly">
        <ButterflySVG />
      </div>
      <VTitle>
        Полезное
        <template #aside>
          <NuxtLink :to="Routes.Articles">
            <div class="popular__more">
              <VHidden :hide="{ xs: false, ml: true }">
                <VInlineButton>
                  <template #icon>
                    <VIcon name="24-outline-right-corner" />
                  </template>
                </VInlineButton>
              </VHidden>

              <VHidden :hide="{ xs: true, ml: false }">
                <VInlineButton icon-position="right">
                  Посмотреть все
                  <template #icon>
                    <VIcon name="24-outline-right-corner" />
                  </template>
                </VInlineButton>
              </VHidden>
            </div>
          </NuxtLink>
        </template>
      </VTitle>
      <VStack direction="row" :gap="stackGap" align="stretch">
        <VStackItem :size="{ xs: 12, l: 8 }">
          <VUsefulItem
            v-intersection-observer="[
              (io) => onVisibilityItem(items[0], io),
              { root },
            ]"
            class="useful__link"
            :title="items[0]?.title"
            :text="items[0]?.description"
            :article="items[0].article"
            :image="items[0]?.imageUrl"
            :size="'wide'"
            :disclaimer="items[0]?.disclaimer"
            @click="onItemClick(items[0])"
          />
        </VStackItem>

        <VStackItem class="hidden_to-l" :size="4">
          <VUsefulItem
            v-intersection-observer="[
              (io) => onVisibilityItem(items[1], io),
              { root },
            ]"
            class="useful__link"
            :title="items[1]?.title"
            :text="items[1]?.description"
            :article="items[1].article"
            :image="items[1]?.imageUrl"
            :disclaimer="items[1]?.disclaimer"
            :size="'square'"
            @click="onItemClick(items[1])"
          />
        </VStackItem>
      </VStack>
    </VWrapperLocal>

    <VHidden :hide="{ xs: false, l: true }">
      <VWrapper class="useful_offset">
        <AppSlider
          :breakpoints="{
            xs: {
              slidesPerView: 'auto',
              spaceBetween: 8,
              slidesOffsetAfter: 12,
              slidesOffsetBefore: 12,
            },
            s: {
              slidesPerView: 'auto',
              spaceBetween: 12,
              slidesOffsetAfter: 20,
              slidesOffsetBefore: 20,
            },
            m: {
              slidesPerView: 'auto',
              spaceBetween: 20,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: 32,
            },
            ml: {
              slidesPerView: 'auto',
              spaceBetween: 20,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: 32,
            },
            l: {
              slidesPerView: 3,
              spaceBetween: 24,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
              navigation: true,
            },
            xl: {
              slidesPerView: 4,
              spaceBetween: 24,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
              navigation: true,
            },
          }"
          @slide-change-by-user="onSlideScroll"
          @navigation-next="onSlideScroll"
          @navigation-prev="onSlideScroll"
        >
          <AppSliderSlide
            v-for="(item, index) in sliderItems"
            :key="index"
            :size="{ xs: 9, s: 8, m: 6, ml: 5, l: 4 }"
          >
            <VUsefulItem
              v-intersection-observer="[
                (io) => onVisibilityItem(item, io),
                { root },
              ]"
              class="useful__link"
              :title="item.title"
              :text="item.description"
              :article="item.article"
              :image="item.imageUrl"
              :disclaimer="item.disclaimer"
              :size="'square'"
              @click="onItemClick(item)"
            />
          </AppSliderSlide>
        </AppSlider>
      </VWrapper>
    </VHidden>

    <VHidden :hide="{ xs: true, l: false }">
      <VWrapperLocal class="useful_offset">
        <VStack wrap="nowrap" direction="row" :gap="stackGap">
          <VStackItem
            v-for="(item, index) in middleItems"
            :key="index"
            :size="4"
          >
            <VUsefulItem
              v-intersection-observer="[
                (io) => onVisibilityItem(item, io),
                { root },
              ]"
              class="useful__link"
              :title="item.title"
              :text="item.description"
              :article="item.article"
              :image="item.imageUrl"
              :disclaimer="item.disclaimer"
              :size="'square'"
              @click="onItemClick(item)"
            />
          </VStackItem>
        </VStack>
      </VWrapperLocal>
    </VHidden>

    <VWrapperLocal class="useful_offset">
      <VStack direction="row" :gap="stackGap" align="stretch">
        <VStackItem class="hidden_to-l" :size="4">
          <VUsefulItem
            v-intersection-observer="[
              (io) => onVisibilityItem(items[5], io),
              { root },
            ]"
            class="useful__link"
            :title="items[5]?.title"
            :text="items[5]?.description"
            :article="items[5].article"
            :image="items[5]?.imageUrl"
            :disclaimer="items[5]?.disclaimer"
            :size="'square'"
            @click="onItemClick(items[5])"
          />
        </VStackItem>

        <VStackItem :size="{ xs: 12, l: 8 }">
          <VUsefulItem
            v-intersection-observer="[
              (io) => onVisibilityItem(items[6], io),
              { root },
            ]"
            class="useful__link"
            :title="items[6]?.title"
            :text="items[6]?.description"
            :article="items[6].article"
            :image="items[6]?.imageUrl"
            :disclaimer="items[6]?.disclaimer"
            :size="'wide'"
            @click="onItemClick(items[6])"
          />
        </VStackItem>
      </VStack>
    </VWrapperLocal>
  </div>
</template>

<style lang="postcss">
.useful__yaslot {
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 48px;
  margin-bottom: 54px;
}

.hidden_to-l {
  display: none;
}

.useful__link {
  cursor: pointer;
}

.useful-butterfly {
  position: relative;
  display: none;

  svg {
    position: absolute;
    top: -96px;
    left: -66px;
    z-index: var(--pl-z-index-negative);
  }
}

.useful .useful_offset {
  margin-top: 18px;
}

@media (--pl-viewport-from-s) {
  .useful .useful_offset {
    margin-top: 32px;
  }
}

@media (--pl-viewport-from-m) {
  .useful__yaslot {
    border-radius: 20px;
    margin-bottom: 58px;
  }
}

@media (--pl-viewport-from-ml) {
  .useful__yaslot {
    margin-top: 64px;
    margin-bottom: 70px;
  }
}

@media (--pl-viewport-from-l) {
  .useful__yaslot {
    border-radius: 24px;
    margin-top: 72px;
    margin-bottom: 82px;
  }

  .hidden_to-l {
    display: block;
  }

  .hidden_from-l {
    display: none;
  }

  .useful-butterfly {
    display: block;
  }

  .useful .useful_offset {
    margin-top: 48px;
  }
}
</style>
