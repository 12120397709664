<script lang="ts" setup>
import { useStoresStore } from "~/store/stores";
import { PromotionType } from "~/enums/PromotionType";

const { requestPromotionsByType } = promoApi();
const { requestShopId } = storeToRefs(useStoresStore());

const { status, data } = await requestPromotionsByType({
  type: [PromotionType.Special, PromotionType.Federal],
  storeId: requestShopId.value,
  limit: 12,
  offset: 0,
});
</script>

<template>
  <SectionsWeekProductsSkeleton v-if="status === 'pending'" />
  <AppProductsSlider
    v-else-if="data?.goods.length"
    :products="data.goods"
    :link="Routes.PromoCatalog"
    title="Товары недели"
    class="week-products"
    stat-prefix="Sections:WeeklyProduct"
  />
</template>

<style lang="postcss" scoped>
.week-products {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 290px;

  @media (--pl-viewport-from-l) {
    min-height: 350px;
  }

  @media (--pl-viewport-from-xl) {
    min-height: 330px;
  }
}
</style>
