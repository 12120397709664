import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

/*
 * Тут должны быть только события для общих компонентов
 * page и префикс для eventName для них вычисляется
 * в зависимости от стр. в useAnalytics.ts
 * Если для события не найден page - оно не будет отправлено
 * */
export default <IAnalyticEventsConfig>{
  "Sections:WeeklyProduct:View": {
    ya: {
      eventName: "weeklyProduct_view",
      page: "",
      block: "weeklyProduct",
    },
  },
  "Sections:WeeklyProduct:Item:View": {
    ya: {
      eventName: "weeklyProduct_item_view",
      page: "",
      block: "weeklyProduct",
      element: "item",
    },
  },
  "Sections:WeeklyProduct:Item:Click": {
    ya: {
      eventName: "weeklyProduct_item_click",
      page: "",
      block: "weeklyProduct",
      element: "item",
    },
  },
  "CatalogListing:AdFoxGoods:View": {
    ya: {
      eventName: "adFoxGoods_view",
      page: "",
    },
  },
  "CatalogListing:AdFoxGoods:Click": {
    ya: {
      eventName: "adFoxGoods_click",
      page: "",
    },
  },
};
