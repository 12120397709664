export const stripDoubleSlash = (str: string) => str.replace(/\/+/g, "/");

export const getErid = (str = "") => {
  // неизвестно как контент заполнит ерид,
  // поэтому забираем все начиная со слова ерид ("erid 2Vtzqx28tzU</span></p>")
  const eridMatch = str.match(/erid\s*[:\s]*(.*)/i);
  return eridMatch?.length ? eridMatch[0] : "";
};

// замена символов конца строки на "<br>"
export const sanitizeString = (str: string) => str.replace(/\r\n|\r|\n/g, "<br>");

export const keepUnicode = function (str: string) {
  return str.replace(/[`~!@#$%^&®*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
};
