<script setup lang="ts">
import { useScroll } from "@vueuse/core";
import VTopButton from "@magnit/core/src/components/VTopButton/VTopButton.vue";
import VModalAdult from "@magnit/unit-catalog/src/components/VModalAdult.vue";
import useAdult from "@magnit/unit-catalog/src/composables/useAdult";
import { showAdultCookie } from "@magnit/unit-catalog/src/unit-catalog.types";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import {
  provideKeys,
  adultStorageKey,
} from "@magnit/unit-catalog/src/unit-catalog.vars";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VYaSlotErrorPopup from "@magnit/core/src/containers/VYaSlot/VYaSlotErrorPopup.vue";
import VModalAuth from "@magnit/core/src/containers/VModalAuth/VModalAuth.vue";
import UserDisclaimer from "~/components/UserDisclaimer.vue";
import VFooter from "~/containers/VFooter/VFooter.vue";
import { useModalsStore } from "~/store/modals";

const nuxt = useNuxtApp();
initLogger(nuxt.$logger);
const router = useRouter();
const route = useRoute();
const adultCookie = useCookie<showAdultCookie>(adultStorageKey);
const userStore = useUserStore();
const { profile } = storeToRefs(userStore);
const categoriesStore = useCategoriesStore();
const storesStore = useStoresStore();
const { loadSlotApiInit } = useSlotsStore();
const { requestShopCode, diffShopCode, isDiffShopCodeQuery } =
  storeToRefs(storesStore);
const slotsStore = useSlotsStore();
const modalsStore = useModalsStore();
const { approved, isDialogOpened, updateApprove } = useAdult(adultCookie.value);
const { kfpCid } = usePublicConfig();
const { $toast } = useNuxtApp();
const { send } = useAnalytics();
const { authorize, isAuthorized, authModalOpen, authModalProps, error } =
  useAuth();

const isAutotests = useCookie(COOKIE_AUTOTESTS_MARK, {
  readonly: true,
  watch: false,
});

const cookiePopupOpen = ref(false);
const showYaSlotPopup = ref(false);
const confirmAgeActive = ref(false);
const elWindow = ref<HTMLElement | Window | null>(null);
const windowScrollDirection = ref<"top" | "bottom">("bottom");

const displayDisclaimer = computed(
  () => authModalProps.value?.form === "phone",
);
const rejectLabel = computed(() =>
  !confirmAgeActive.value && isGoodsProductPage.value
    ? "Нет, перейти на главную"
    : undefined,
);
const isGoodsProductPage = computed(
  () => RouteNames.ProductGoods === route.name,
);
const windowScroll = useScroll(elWindow);

const displayMainSlot = computed(() => !isDiffShopCodeQuery.value);
const skeletonComponent = computed(() => {
  if (
    route.name === "promo-catalog-category-categorySlug" ||
    route.name === "catalog-category-slug"
  ) {
    return resolveComponent("LazyVSkeletonCatalogPage");
  } else if (route.name === "popular") {
    return resolveComponent("LazyVSkeletonPopularPage");
  }
  return resolveComponent("LazyVSkeletonCommonPage");
});

provide(provideKeys.approved, approved);
provide(provideKeys.isDialogOpened, isDialogOpened);
provide(provideKeys.updateApprove, updateApprove);
provide(LocalProvidersKeys.ageConfirmationActive, confirmAgeActive);

useAuthDestination(async (destination, clear) => {
  if (authModalOpen.value || isAuthorized.value) return;
  try {
    scrollLock.enable();
    const result = await authorize();
    if (!result.isRegistered) router.push(Routes.Registration);
    else if (result.profile) {
      profile.value = {
        ...result.profile,
        birthDate: result.profile.birthDate
          ? birthDateToDate(result.profile.birthDate)
          : undefined,
      };
      if (destination) {
        router.push(destination);
        clear();
      } else {
        router.push(Routes.Profile);
      }
    } else {
      throw new Error("Пустой ответ от авторизации");
    }
  } finally {
    authModalOpen.value = false;
    scrollLock.disable();
  }
});

await Promise.all(
  [
    storesStore.initializeSelectedStore(),
    !diffShopCode.value && categoriesStore.requestCategories(),
  ].filter(Boolean),
);
await categoriesStore.requestCategories();

const onReject = () => {
  if (!isGoodsProductPage.value || confirmAgeActive.value) return;
  router.push(Routes.Main);
};

const cookiePolicyOnVisible = (v: boolean) => {
  cookiePopupOpen.value = v;
};

onBeforeMount(() => {
  if (window.location.host === "cosmetic.magnit.ru") {
    document.cookie = "mg_at=; Max-Age=-99999999;";
  }
});

onMounted(() => {
  if (!isAuthorized.value) return;
  nextTick(() => {
    userStore.getProfileSummary();
  });
});

onMounted(() => {
  elWindow.value = window;
  loadSlotApiInit();
  loadScript(`/scripts/${kfpCid.value}.das.obf.js`);
});

watch(isAuthorized, async (authorized) => {
  console.warn("isAuthorized changed");
  if (authorized) {
    await userStore.getProfileSummary();
  } else {
    userStore.$reset();
    userStore.clearSavedUUID();
  }
});
watch(requestShopCode, () => categoriesStore.requestCategories(true));
watch(error, (next) => {
  if (!next) return;
  $toast.warning({ title: LABEL_UNKNOWN_ERROR_SUB });
});

watchEffect(() => {
  if (windowScroll.isScrolling) {
    if (windowScroll.directions.top) {
      windowScrollDirection.value = "top";
    } else if (windowScroll.directions.bottom) {
      windowScrollDirection.value = "bottom";
    }
  }
});

watchEffect(() => {
  showYaSlotPopup.value =
    !slotsStore.slotPopupWasDisplayed &&
    !modalsStore.getActive() &&
    !cookiePopupOpen.value &&
    slotsStore.slotStatus === IYaSlotApiStatus.ERROR;
  if (showYaSlotPopup.value) {
    slotsStore.slotPopupWasDisplayed = true;
  }
});
</script>

<template>
  <Body
    class="body"
    :class="{
      'body_scrolled':
        windowScroll && windowScroll.y.value > LAYOUT_BODY_SCROLLED_PADDING,
      'body_scrolled-top':
        windowScroll
        && windowScroll.y.value > 0
        && windowScrollDirection === 'top',
      'body_scrolled-bottom':
        windowScroll
        && windowScroll.y.value > 0
        && windowScrollDirection === 'bottom',
    }"
  />
  <LayoutDefaultHeader />
  <div v-show="displayMainSlot" class="page">
    <slot />
  </div>
  <component :is="skeletonComponent" v-if="!displayMainSlot" />
  <VFooter />

  <VCookiePolicyPopup @visible="cookiePolicyOnVisible" />
  <VYaSlotErrorPopup :show="showYaSlotPopup" />
  <VTopButton />
  <VModalAdult :reject-label="rejectLabel" @reject="onReject" />
  <client-only>
    <VModalAuth
      v-bind="authModalProps"
      :captcha-key="authModalProps.captchaKey"
      :disable-captcha="Boolean(isAutotests)"
    >
      <template #disclaimer>
        <UserDisclaimer
          v-if="displayDisclaimer"
          class="page-login__modal-auth-disclaimer"
          @click:agreement="send('RegistrationPage:Agreement:Click')"
          @click:rules="send('RegistrationPage:Rules:Click')"
          @click:conditions="send('RegistrationPage:Conditions:Click')"
        />
      </template>
    </VModalAuth>
  </client-only>
</template>

<style lang="postcss">
.page {
  padding-top: 16px;
}
</style>
