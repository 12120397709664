<script setup lang="ts">
// [TO DO] Заменить на компонент layer-profile/LProfileUserDisclaimer
import VText from "@magnit/core/src/components/VText/VText.vue";

const emit = defineEmits<{
  "click:agreement": [];
  "click:conditions": [];
  "click:rules": [];
}>();
</script>

<template>
  <VText
    class="lprofile-user-disclaimer"
    tag="div"
    font="body-small-regular"
    color="secondary"
  >
    <span>Нажимая кнопку «Далее»,<br>вы принимаете </span>
    <a
      href="https://dostavka.magnit.ru/express/user_soglas"
      rel="noreferrer"
      target="_blank"
      @click="emit('click:agreement')"
    >пользовательское соглашение</a>
    <span>, </span>
    <a
      href="https://magnit.ru/pdn/"
      rel="noreferrer"
      target="_blank"
      @click="emit('click:conditions')"
    >политику&nbsp;кофиденциальности
    </a>
    <span>и&nbsp;</span>
    <a
      href="https://magnit.ru/terms/"
      target="_blank"
      rel="”noopener”"
      @click="emit('click:rules')"
    >правила программы&nbsp;лояльности</a>
  </VText>
</template>

<style lang="postcss" scoped>
.lprofile-user-disclaimer {
  margin-top: var(--pl-unit-x4);
  text-align: center;

  :deep(a) {
    color: var(--pl-text-link-default);
    text-decoration: none;
  }
}
</style>
