enum HttpCodes {
  Success = 200,
  NoContent = 204,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Error4xx = 400,
  Error5xx = 500,
}

enum ErrorCodes {
  Captcha = "CaptchaError",
  CardMergeBlocked = "cardMergeBlocked",
  IncompleteProfile = "incompleteProfile",
  EmailForbidden = "emailForbiddenToUse",
  ValidationError = "validationError",
}

/**
 * @swagger web-gateway: https://web-gateway.dev.ya.magnit.ru/docs/index.html
 * @swagger magnit-id: https://id.dev.ya.magnit.ru/docs/index.html
 */
const urls = {
  "geohelper": {
    /**
     * Поиск магазина по ID или Code. В запросе требуется передать только один из параметров.
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/geohelper/get_v1_stores_store
     */
    store: "/v1/stores/store",
  },

  "otp": {
    /**
     * Генерация и отправка OTP кода по номеру телефона
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDSendOTPV1
     */
    getCode: "/v1/auth/otp",
    /**
     * Проверка OTP и получение MagnitID Code кода для авторизации нового пользователя
     * @link: hhttps://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDCheckOTPV1
     */
    checkCode: "/v1/auth/otp/check",
  },

  "auth": {
    /**
     * Авторизация
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDGenerateTokenV1
     */
    login: "/v1/auth/token",
    /**
     * Обновление JWT токена
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDRefreshTokenV1
     */
    refresh: "/v1/auth/token/refresh",
  },

  "user": {
    /**
     * Получаем информацию по бонусам и магнитикам
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/balance/get_v2_user_bonus_balance
     */
    balance: "/v2/user/bonus-balance",
    /**
     * Получаем карточки пользователя
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/get_v2_user_cards
     */
    cards: "/v2/user/cards",
    /**
     * Смерживаем вертуальную и физическую карты
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/post_v3_user_card_merge
     */
    cardMerge: "/v3/user/card/merge",
    /**
     * Подтверждение e-mail
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDEmailConfirmV1
     */
    emailConfirmation: "/v1/profile/email-confirm",
    /**
     * Регистрация нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDRegisterProfileByMagnitIDCodeV1
     */
    register: "/v3/profile/register/magnit-id-code",
    /**
     * Получаем профиль пользователя или обноваляем (patch)
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDGetProfileV3
     */
    profile: "/v1/profile",
    /**
     * Разрешения пользователя на получение sms, email, чеков на email.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/profiles/get_v1_profile_permissions
     */
    permissions: "/v2/profile/permissions",
  },

  "city": {
    /**
     * Получаем информации о городе по IP пользователя
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_geolocation_ip
     */
    ip: "/v1/geolocation/ip",
    /**
     * Получаем информации о городе по координатам пользователя
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_geolocation_city
     */
    coords: "/v1/geolocation/city",
    /**
     * Получаем список городов, в которых есть магазины
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_cities
     */
    cities: "/v1/cities",
    /**
     * Получаем список магазинов в городе
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_stores
     */
    stores: "/v1/stores",
  },

  "promo": {
    /**
     * Получаем список промо-категорий
     * https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV2PromotionsCategories
     */
    categories: "/v2/promotions/categories",
    /**
     * Получаем список промо-товаров по категории
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV1Promotions
     */
    products: "/v1/promotions",
    /**
     * Получаем информации о промо-акции
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV2PromotionsItem
     */
    item: "/v2/promotions/item",
    /**
     * Получаем товаров по типу акции
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promocacher/promocacherGetV1PromotionsType
     */
    type: "/v1/promotions/type",

    /**
     * Полнотекстовый поиск промоакций
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/promosearcher/promosearcherGetV1PromotionsSearch
     */
    search: "/v1/promotions/search",
  },

  // Взаимодействие с промолоадером. Данные получаем из сервиса promoloader-api.
  "promoloader": {
    /** Акционные подборки /v1/promos/mp/{shopCode}/{promoId}
     * @link https://omni-web.k8s.dev.mos.corp/docs#/promoloader-api/promoloaderApiGetV1PromosMpByShopCodeByPromoId
     */
    selections: "/v1/promos/mp",
  },

  "goods": {
    /**
     * Поиск товаров по категориям или по поисковой строке (обновленный контракт с поддержкой Маркета)
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV2GoodsSearch
     */
    products: "/v2/goods/search",
    /**
     * Получение дерева категорий.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV3CategoriesStoreByStoreCode
     */
    categories: "/v3/categories/store",
    /**
     * Получение товаров согласно переданному списку id товаров и коду магазина
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/goods/post_v1_goods_store
     */
    productsByIDs: "/v1/goods/store",
    /**
     * Получаем информации о товаре
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV2GoodsByGoodIdStoresByStoreCode
     */
    product: "/v2/goods/",
    /**
     * Получение фильтров для товарного каталога
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV1GoodsFilters
     */
    filters: "/v1/goods/filters",
  },

  "userReviews": {
    /**
     * Список оценок объекта
     * @link: http://omni-web.k8s.dev.mos.corp/docs#/user-reviews/userReviewsGetV1ObjectByObjectIdScores
     */
    objectScores: (id: string) => `/v1/object/${id}/scores`,
  },

  "content": {
    /**
     * Данные для главной страницы.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_main_page
     */
    mainPage: "/v1/cosmetic/content/main-page",
    /**
     * Данные для страницы промо-каталога
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_catalog
     */
    catalog: "/v1/cosmetic/content/catalog",
    /**
     * Получение статьи по ID.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_cookies
     */
    article: "/v1/cosmetic/content/article",
    /**
     * Получение публикаций.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/post_v1_cosmetic_content_publications
     */
    publications: "/v1/cosmetic/content/publications",
    /**
     * Получение брендированных товарных категорий.
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/cosmetic/get_v1_cosmetic_content_branded_goods_categories
     */
    brandedGoodsCategories: "/v1/cosmetic/content/branded-goods-categories",
  },

  "store-search": {
    /** Получение списка магазинов по поисковой строке.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchAddress
     */
    address: "/v1/store-search/address",
    /** Получение списка магазинов по геолокации.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchPostV1StoreSearchGeo
     */
    geo: "/v1/store-search/geo",
    /** Получение магазина по его коду.
     * @link https://omni-web.k8s.dev.mos.corp/docs#/store-search/storeSearchGetV1StoreSearchByStoreCode
     */
    store: "/v1/store-search",
  },
};

export { urls, HttpCodes, ErrorCodes };
