import type { IAppDestination } from "~/typings/app";

export default (onChange?: (destinatin: IAppDestination, removeDestination: () => void) => Promise<void>) => {
  const router = useRouter();
  const route = useRoute();
  const authDestination = useState<IAppDestination | null>(STATE_AUTH_DESTINATION, () => null);

  const setAuthDestination = (target?: IAppDestination) => {
    authDestination.value = target || { path: route.path, query: route.query, params: route.params } as IAppDestination;
  };
  const authorizeUser = () => setAuthDestination({ path: Routes.Profile });
  const applyAuthDestination = (dest: IAppDestination = { path: Routes.Profile }) => {
    if (authDestination.value) router.push(authDestination.value);
    else if (dest) router.push(dest);
    authDestination.value = null;
  };
  const removeAuthDestination = () => {
    authDestination.value = null;
  };

  if (onChange) {
    const initChangeListener = async () => {
      if (!authDestination.value) return;
      await onChange(authDestination.value, removeAuthDestination);
    };
    onMounted(initChangeListener);

    watch(authDestination, initChangeListener);
  }

  return {
    authDestination,
    setAuthDestination,
    authorizeUser,
    applyAuthDestination,
    removeAuthDestination,
  };
};
