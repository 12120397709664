<script lang="ts" setup>
const { weekProductsCategory } = usePublicConfig();
/**
 * ToDo: после релиза переезда товаров недели в товарный каталог - блок с промо можно будет вырезать
 */
</script>

<template>
  <SectionsWeekProductsGoods v-if="weekProductsCategory" />
  <SectionsWeekProductsPromo v-else />
</template>
