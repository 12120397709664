<script setup lang="ts">
import { ref, reactive } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import VProductPreview from "@magnit/unit-catalog/src/components/VProductPreview.vue";
import { Swiper as SwiperClass } from "swiper";
import VTitle from "~/components/VTitle.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import useMainPageCategoryProducts from "~/composables/useMainPageCategoryProducts";
import {
  SLOT_PLACEHOLDER_IMAGE_SRC,
  SLOT_PLACEHOLDER_URL,
  useContentStore,
} from "~/store/content";

const contentStore = useContentStore();
const slotApi = useSlotsStore();
const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();

const root = ref(null);
const isVisible = ref(false);

const onMoreClick = () => {
  send("MainPage:NewProducts:Forward:Click");
};

const onItemClick = (item: IProduct, index: number) => {
  send("MainPage:NewProducts:Item:Click", {
    position: index + 1,
    item_id: item.id,
    item_name: item.title,
    dest: item.link,
  });
};

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting) {
    send("MainPage:NewProducts:View");
  }
};

const onSlideScroll = (swiper: SwiperClass) => {
  send("MainPage:NewProducts:Scroll", {
    scroll_line: swiper.realIndex > swiper.previousIndex ? "right" : "left",
  });
};

function onVisibilityItem(
  item: IProduct,
  index: number,
  [{ isIntersecting }]: IntersectionObserverEntry[],
) {
  if (isIntersecting) {
    send("MainPage:NewProducts:Item:View", {
      position: index + 1,
      item_id: item.id,
      item_name: item.title,
      dest: item.link,
    });
  }
}

const data = contentStore.mainPage?.newArrival
  ? reactive(useMainPageCategoryProducts(contentStore.mainPage.newArrival))
  : null;
</script>

<template>
  <div
    v-if="
      data
        && contentStore.mainPage?.newArrival
        && data.productsWithYaSlot.length
    "
    ref="root"
    v-intersection-observer="onVisibility"
    class="novelties"
  >
    <VWrapperLocal>
      <VTitle>
        {{ contentStore.mainPage?.newArrival?.title }}

        <template #aside>
          <NuxtLink :to="data.link">
            <div class="novelties__more">
              <VHidden :hide="{ xs: false, ml: true }">
                <VInlineButton @click="onMoreClick">
                  <template #icon>
                    <VIcon name="24-outline-right-corner" />
                  </template>
                </VInlineButton>
              </VHidden>

              <VHidden :hide="{ xs: true, ml: false }">
                <VInlineButton icon-position="right" @click="onMoreClick">
                  Посмотреть все
                  <template #icon>
                    <VIcon name="24-outline-right-corner" />
                  </template>
                </VInlineButton>
              </VHidden>
            </div>
          </NuxtLink>
        </template>
      </VTitle>
    </VWrapperLocal>

    <VWrapper class="novelties__slider-wrapper">
      <AppSlider
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            spaceBetween: 8,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
            grid: {
              fill: 'row',
              rows: 1,
            },
          },
          s: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
            grid: {
              fill: 'row',
              rows: 1,
            },
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
            grid: {
              fill: 'row',
              rows: 1,
            },
          },
          ml: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
            grid: {
              fill: 'row',
              rows: 1,
            },
          },
          l: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            grid: {
              fill: 'row',
              rows: 2,
            },
          },
        }"
        @slide-change-by-user="onSlideScroll"
        @navigation-next="onSlideScroll"
        @navigation-prev="onSlideScroll"
      >
        <AppSliderSlide
          v-for="(item, index) in data.productsWithYaSlot"
          :key="item.id"
          :size="{ xs: 8, m: 5, ml: 4, l: 'auto' }"
          class="novelties__item"
          :class="{
            novelties__item_placeholder: Boolean('slotConfig' in item),
          }"
        >
          <VYaSlot
            v-if="'slotConfig' in item"
            :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
            :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
            :owner-id="adfoxOwnerId"
            :config="item.slotConfig"
            :placeholder-image-src="SLOT_PLACEHOLDER_IMAGE_SRC"
            :placeholder-url="SLOT_PLACEHOLDER_URL"
            :analytics-sender="send"
            :analytics-view="{
              name: 'MainPage:NewProducts:AdFox:View',
            }"
            :analytics-click="{
              name: 'MainPage:NewProducts:AdFox:Click',
            }"
            with-hover
            has-product
            :disclaimer="contentStore.mainPage?.newArrival?.disclaimer"
            @stub="data.onStabAdfox(index)"
          />
          <template v-else>
            <VProductPreview
              :id="item.id || ''"
              v-intersection-observer="[
                (io) => onVisibilityItem(item, index, io),
                { root },
              ]"
              :link="item.link || ''"
              v-bind="item"
              @click="onItemClick(item, index)"
            />
          </template>
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </div>
</template>

<style lang="postcss">
.novelties {
  &__more {
    display: flex;
    align-items: center;
  }

  &__item {
    height: auto;
    cursor: pointer;

    .swiper-grid &_placeholder {
      height: auto !important;
    }

    & .disclaimer {
      bottom: var(--pl-unit-x2);
      right: var(--pl-unit-x3);
    }
  }

  .swiper-slide {
    height: unset !important;
  }
}

@media (--pl-viewport-from-l) {
  .novelties .novelties__slider-wrapper {
    padding: 0 32px;
  }
}
</style>
