<script setup lang="ts">
import { ref } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import { Swiper as SwiperClass } from "swiper";
import VTitle from "~/components/VTitle.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import VSpecialOffer from "~/containers/VSpecialOffers/VSpecialOffer.vue";
import { useContentStore } from "~/store/content";

const contentStore = useContentStore();

// Analytics
const { send } = useAnalytics();
const isVisible = ref(false);
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting) {
    send("MainPage:Specials:View");
  }
};

const onSlideClick = (swiper: SwiperClass) => {
  send("MainPage:Specials:Item:Click", {
    position: swiper.clickedIndex + 1,
    title: contentStore.mainPage?.specialOffers[swiper.clickedIndex].title,
    dest: contentStore.mainPage?.specialOffers[swiper.clickedIndex].offerUrl,
  });
};
const onSlideScroll = (swiper: SwiperClass) => {
  send("MainPage:Specials:Scroll", {
    scroll_line: swiper.realIndex > swiper.previousIndex ? "right" : "left",
  });
};

const root = ref(null);

function onVisibilityItem(
  index: number,
  [{ isIntersecting }]: IntersectionObserverEntry[],
) {
  if (isIntersecting) {
    send("MainPage:Specials:Item:View", {
      position: index + 1,
      title: contentStore.mainPage?.specialOffers[index].title,
      dest: contentStore.mainPage?.specialOffers[index].offerUrl,
    });
  }
}
</script>

<template>
  <div
    v-if="contentStore.mainPage?.specialOffers?.length"
    ref="root"
    v-intersection-observer="onVisibility"
    class="special-offers"
  >
    <VWrapperLocal>
      <VTitle>Специальные предложения</VTitle>
    </VWrapperLocal>

    <VWrapper class="special-offers__slider-wrapper">
      <AppSlider
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            spaceBetween: 8,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
          },
          s: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          ml: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          l: {
            slidesPerView: 3,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
          },
          xl: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
          },
        }"
        @slide-click="onSlideClick"
        @slide-change-by-user="onSlideScroll"
        @navigation-next="onSlideScroll"
        @navigation-prev="onSlideScroll"
      >
        <AppSliderSlide
          v-for="(item, index) in contentStore.mainPage.specialOffers"
          :key="item.title"
          :size="{ xs: 9, m: 6, ml: 4, l: 'auto' }"
          class="special-offers__item"
        >
          <VSpecialOffer
            v-intersection-observer="[
              (io) => onVisibilityItem(index, io),
              { root },
            ]"
            :title="item.title"
            :text="item.description"
            :image="item.publicImageUrl"
            :size="item.slideType === 0 ? 'big' : 'small'"
            :url="item.offerUrl"
            :start-date="item.promoStartDate"
            :end-date="item.promoEndDate"
            :disclaimer="item.disclaimer"
          />
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </div>
</template>

<style lang="postcss">
.special-offers__item {
  .special-offers__slider-wrapper & {
    flex-direction: column;
    display: flex;
    height: auto;
  }
}

@media (--pl-viewport-from-l) {
  .special-offers .special-offers__slider-wrapper {
    padding: 0 32px;
  }
}
</style>
