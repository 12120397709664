export default defineNuxtRouteMiddleware(async (to, _) => {
  const router = useRouter();
  const { cookieJWT, cookieMagnitCode } = useAuthCookies();
  const { setAuthDestination } = useAuthDestination();
  const incompleteProfile = useCookie<string>(
    APP_COOKIE_STORAGE_KEYS.incompleteProfile,
  );

  if (!cookieJWT.value) {
    if (cookieMagnitCode.value && to.path === Routes.Registration) {
      return;
    }

    if (privateRoutes.includes(to.path)) {
      setAuthDestination();
      // тут пока-что делаем через router,
      // т.к. navigateTo на SSR делает серверный редирект и он теряет инфу гидрации про authDestination
      return router.push(Routes.Main);
    } else if (to.path === Routes.ProfileIncomplete) {
      return navigateTo(Routes.Main);
    }
  } else {
    if (incompleteProfile.value && privateRoutes.includes(to.path)) {
      return navigateTo(Routes.ProfileIncomplete);
    }

    if (to.path === Routes.Registration) {
      return navigateTo(Routes.Profile);
    }
  }
});
