import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "RegistrationPage:View": {
    ya: {
      eventName: "auth_personal_view",
      chapter: "auth",
      page: "personal",
    },
  },
  "RegistrationPage:Back:Click": {
    ya: {
      eventName: "auth_personal_back_click",
      chapter: "auth",
      page: "personal",
      element: "back",
    },
  },
  "RegistrationPage:Name:Click": {
    ya: {
      eventName: "auth_personal_name_click",
      chapter: "auth",
      page: "personal",
      block: "name",
    },
  },
  "RegistrationPage:Birthday:Click": {
    ya: {
      eventName: "auth_personal_birth_click",
      chapter: "auth",
      page: "personal",
      block: "birth",
    },
  },
  "RegistrationPage:Subscribe:SMS:Click": {
    ya: {
      eventName: "auth_personal_notice_sms_click",
      chapter: "auth",
      page: "personal",
      block: "notice",
      element: "sms",
      turn_on: "",
    },
  },
  "RegistrationPage:Submit": {
    ya: {
      eventName: "auth_personal_continue_click",
      chapter: "auth",
      page: "personal",
      block: "continue",
    },
  },
  "RegistrationPage:UserAgreement:Click": {
    ya: {
      eventName: "auth_personal_infoUserAgreement_click",
      chapter: "auth",
      page: "personal",
      element: "infoUserAgreement",
    },
  },
  "RegistrationPage:PrivacyPolicy:Click": {
    ya: {
      eventName: "auth_personal_infoPrivacyPolicy_click",
      chapter: "auth",
      page: "personal",
      element: "infoPrivacyPolicy",
    },
  },
  "RegistrationPage:LoyaltyProgramRules:Click": {
    ya: {
      eventName: "auth_personal_infoLoyaltyProgramRules_click",
      chapter: "auth",
      page: "personal",
      element: "infoLoyaltyProgramRules",
    },
  },
  "RegistrationPage:Success": {
    ya: {
      eventName: "reg_success",
    },
  },
};
